import axios from 'axios'

const API = axios.create({
  baseURL: window.location.hostname.includes("flexvastgoedcrm")
  ? "https://server.kantoorflex.nl/api/rest/v2"
  : "https://server.kantoorflex.nl/api/rest/v2",
  timeout: 30000,
});


export default {
  

   async getAllPlaces() {
    try {
      const response = await API.get(`/office`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },


  async getPlaceByID(id) {
    try {
      const response = await API.get(`/office/${id}`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },


  async getTotalOffices() {
    try {
      const response = await API.get(`/office/total`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getOptions() {
    try {
      const response = await API.get(`/office/options`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async getCategories() {
    try {
      const response = await API.get(`/office/categorie/options`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

 async deleteOffice(officeId) {
    try {
      // Show a confirmation dialog to the user
      
        const response = await API.delete(`/office/${officeId}`);
        return response.data;
  
      } catch (error) {
        console.error(error);
      }
    },

    async getOwnerOffices() {
      try {
        const response = await API.get(`/office/myowner/offices`)
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
 
}