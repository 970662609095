<template>
  <div class="space">
    <v-container>
      <v-card elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Basis informatie</v-card-title>
        </v-row>
        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="10" sm="10">
            <v-text-field
              v-model="office.officeName"
              outlined
              label="Titel"
              
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-select
              v-model="office.categories"
              :items="categories"
              item-value="categorie_id"
              item-text="categorie"
              multiple
              outlined
              label="Selecteer de categorie"
            ></v-select>
          </v-col>
          <v-col col="5" sm="5">
            <v-select
              v-model="office.facilities"
              :items="facilities"
              item-value="facilitie_id"
              item-text="facilitie"
              multiple
              outlined
              label="Selecteer de faciliteiten"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">locatie en prijs </v-card-title>
        </v-row>
        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-card outlined>
              <gmap-autocomplete
                class="col-12"
                v-model="office.adres"
                placeholder="Adres toevoegen"
                @place_changed="Data"
                :rules="nameRules"
                required
              >
              </gmap-autocomplete>
            </v-card>
          </v-col>

          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.price"
              prepend-inner-icon="mdi-currency-eur"
              outlined
              label="Prijs"
              :rules="priceRules"
              required
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="5" sm="5">
            <v-text-field
              v-model="office.totalworkspace"
              type="number"
              prepend-inner-icon="mdi-chair-rolling"
              outlined
              label="Aantal werkplekken"
            />
          </v-col>

          <v-col cols="5" sm="5">
            <v-text-field
              v-model="office.square"
              type="number"
              prepend-inner-icon="mdi-set-square"
              outlined
              label="Aantal vierkante meter"
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Content</v-card-title>
        </v-row>

        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.youtube"
              prepend-inner-icon="mdi-youtube"
              outlined
              label="Youtube link"
            />
          </v-col>

          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.facebook"
              prepend-inner-icon="mdi-facebook"
              outlined
              label="Facebook link"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.instagram"
              prepend-inner-icon="mdi-instagram"
              outlined
              label="Instagram link"
            />
          </v-col>

          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.twitter"
              prepend-inner-icon="mdi-twitter"
              outlined
              label="twitter link"
            />
          </v-col>
        </v-row>
      </v-card>



        <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Content</v-card-title>
        </v-row>

        <v-divider color="primary"></v-divider>
<v-row style="margin-left:60px">
 <v-col col="10" sm="10">
         <v-file-input
              v-model="uploadImagePlaceholder"
              :multiple="true"
              accept="image/*"
              placeholder="Selecteer een bestand(en) of sleep het hier (afbeeldingen worden direct geupload)"
              drop-placeholder="Drop file here..."
              @change="onFileChange"
            ></v-file-input>
            <div class="image-container">
              <div
                v-for="img in office.images"
                :key="img.image_id"
                class="image-wrapper"
              >
                <img
                  :src="`https://server.kantoorflex.nl/api/static/${img.filename}`"
                  loading="lazy"
                />
                <i class="fa fa-trash" @click="deleteImg(img.image_id)"></i>
              </div>
            </div>
             </v-col>
        </v-row>
      </v-card>

      <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Extra informatie</v-card-title>
        </v-row>

        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="10" sm="10">
            <v-textarea
              v-model="office.description"
              clearable
              clear-icon="mdi-close-circle"
              label="Text"
              value="extra informatie voor de klant."
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" sm="10" style="margin-bottom: 30px">
            <v-btn
              color="primary"
              dark
              elevation="2"
              style="margin-right: 5px"
              @click="updateOffice(office.office_id)"
            >
              Opslaan
            </v-btn>
            <v-btn color="danger" dark elevation="2" @click="$router.go(-1)">
              Afsluiten
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import apiOffice from "../api/apiOffice";
import axios from "axios";
export default {
  data: () => ({
      uploadImagePlaceholder: [],
    hasNewAdress: false,
    facilities: [],
    categories: [],
    nameRules: [
      (v) => !!v || "Dit veld is required",
      (v) => v.length <= 5 || "Minimaal 5 karakakter",
    ],
    priceRules: [
      (v) => !!v || "Dit veld is required",
      (v) => v.length <= 1 || "Minimaal 1 karakakter",
    ],
    office: {
      officeName: "",
      model: "",
      price: "",
      number: "",
      youtube: "",
      facebook: "",
      twitter: "",
      instagram: "",
      description: "",
      square: "",
      facilities: [],
      categories: [],
    },
    AdresData: {
      address: "",
      city: "",
      long_coord: "",
      lat_coord: "",
    },
  }),
  async mounted() {
    await this.getOfficeByID();
    this.hasNewAdress = false;
        apiOffice.getOptions().then((data) => {
      this.facilities = data.facilities;
    });
    apiOffice.getCategories().then((data) => {
      this.categories = data.categories;
    });
  },
  methods: {
    Data(AdresData) {
      this.AdresData = {
        adres: AdresData.formatted_address,
        stad: AdresData.vicinity,
        long_coord: AdresData.geometry.location.lng(),
        lat_coord: AdresData.geometry.location.lat(),
      };
      this.hasNewAdress = true;
    },

    async updateOffice() {
      await axios.put(
        `https://server.kantoorflex.nl/api/office/${this.$route.params.id}`,
        this.hasNewAdress ? { ...this.office, ...this.AdresData } : this.office
      );
      this.$swal.fire("Gewijzigd!", "", "success");
      this.getOfficeByID();
      this.hasNewAdress = false;
    },
    resetForm() {
      this.office.officeName = "";
      this.office.facilities = "";
      this.office.categories = "";
      this.office.model = "";
      this.office.price = "";
      this.office.number = "";
      this.office.youtube = "";
      this.office.facebook = "";
      this.office.twitter = "";
      this.office.instagram = "";
      this.office.description = "";
      this.office.square = "";
      this.AdresData.address = "";
      this.AdresData.long_coord = "";
      this.AdresData.lat_coord = "";

    },

 

    async getOfficeByID() {
      const response = await axios.get(
        `https://server.kantoorflex.nl/api/office/${this.$route.params.id}`
      );
      this.office = response.data;
    },
// Images Controller -->

      async deleteImg(id) {
      const result = await this.$swal.fire({
        title: "Afbeelding verwijderen",
        showDenyButton: true,
        confirmButtonText: "Ja",
        denyButtonText: "Nee",
      });
      if (result.isConfirmed) {
        await axios.delete(
          `https://server.kantoorflex.nl/api/house/${this.$route.params.id}/images/${id}`
        );
        await this.getHouseImages();
      }
    },
    async getHouseImages() {
      const response = await axios.get(
        `https://server.kantoorflex.nl/api/office/${this.$route.params.id}/images`
      );
      this.office.images = response.data;
    },
    async onFileChange(ev) {
      this.$swal.showLoading();
      try {
        const files = new FormData();
        ev.target.files.forEach((file) => {
          files.append("file", file);
        });
        await axios.post(
          `https://server.kantoorflex.nl/api/house/${this.$route.params.id}/image`,
          files,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        await this.getHouseImages();
        await this.$swal.fire("Afbeeldingen zijn geupload", "", "success");
      } catch (error) {
        this.$swal.fire("Afbeeldingen zijn niet geupload", "", "error");
      } finally {
        ev.target.value = null;
        this.uploadImagePlaceholder = [];
        this.$swal.hideLoading();
      }
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 50px;
}
.officeName {
  margin-left: 50px;
  margin-top: 30px;
}
.cards {
  margin-top: 10px;
}
</style>
